import React                  from "react";
import PropTypes              from 'prop-types';
import Modal                  from "../Modal";
import {formatNumberToLength} from "../../utils/CommonUtils";
import Translations           from '../../utils/Translations';
import withCountdown          from "../HigherOrderComponents/Utility/withCountdown";
import Flux                   from "../../flux/Flux";
import {VXPay}                from '../../utils/VXPay';

// errors
const ERROR_TIMEOUT = 4;
const ERROR_UNIQUE  = 6;

class ModalBoxTrialConversionChat extends React.Component {

	static getTranslationByErrorCode(errorCode) {
		switch (errorCode) {
			case ERROR_TIMEOUT:
				return Translations.get('ModalBoxPincallErrorTimeOut');
			default:
				return Translations.get('ModalBoxPincallError');
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			isVerified: false,
			errorCode:  null,
		};

		this.onBackBtnClick          = this.onBackBtnClick.bind(this);
		this.onConversionChatUpdate  = this.onConversionChatUpdate.bind(this);
		this.onOpenTrialPaytourClick = this.onOpenTrialPaytourClick.bind(this);
		this.onOpenPaytourClick      = this.onOpenPaytourClick.bind(this);
		this.getModalRef             = this.getModalRef.bind(this);
	}

	componentDidMount() {
		Flux.Guest.addConversionChatUpdateEvent(this.onConversionChatUpdate);
	}

	componentWillUnmount() {
		Flux.Guest.removeConversionChatUpdateEvent(this.onConversionChatUpdate);
	}

	getModalRef(ref) {
		if (ref) {
			this.refModal = ref;
		}
	}

	onBackBtnClick(e) {
		if (e.preventDefault) {
			e.preventDefault();
		}

		if (e.stopPropagation) {
			e.preventDefault();
		}

		this.setState({
			errorCode: null,
		});
	}

	onOpenTrialPaytourClick(e) {
		if (e.preventDefault) {
			e.preventDefault();
		}

		if (e.stopPropagation) {
			e.preventDefault();
		}

		VXPay.openTrialPaytour({
			texts: {
				'PaymentHeadline':     Translations.get('ModalBoxTrialConversionChatBtnLabel'),
				'PaymentTypeHeadline': Translations.get('ModalBoxTrialConversionChatVXPayPaymentTypeHeadline'),
			},
		});

		VXPay.onSuccess((result) => {
			if (result.success) {
				Flux.Guest.updateConversionChat(true);
			}
		});
	}

	onOpenPaytourClick(e) {
		if (e.preventDefault) {
			e.preventDefault();
		}

		if (e.stopPropagation) {
			e.preventDefault();
		}

		VXPay.openPaytour({option: Flux.Constants.PromoCodes.CONVERSION_CHAT_TRIAL});

		this.onCloseClick();
	}

	onConversionChatUpdate(success) {
		if (success) {
			this.setState({
				isVerified: true,
			});
		} else {
			this.setState({
				errorCode: ERROR_UNIQUE,
			});
		}
	}

	onChoseAmateurBtnClick() {
		window.location.href = '/?jump=freechat';
	}

	onCloseClick() {
		if (this.refModal) {
			this.refModal.removeModal();
		}
	}

	render() {
		let modalContent = null;
		let fixedHeight  = !this.props.isMobile ? 600 : null;
		const timer      = <div className={'pincall-modal-box__countdown'}>
			<img src="/assets/img/conversion-chat/red-clock.svg" alt="countdown" width={40} />
			<div className="pincall-modal-box__countdown__headline">{Translations.get('ModalBoxPincallCountdownHeadline')}</div>
			<div className="pincall-modal-box__countdown__counter">
				{formatNumberToLength(this.props.countdown.hours, 2)}:
				{formatNumberToLength(this.props.countdown.minutes, 2)}:
				{formatNumberToLength(this.props.countdown.seconds, 2)}
			</div>
		</div>;

		if (this.state.errorCode === ERROR_UNIQUE) {
			fixedHeight = null;

			modalContent = <div>
				<div className="pincall-modal-box__headline--error"
				     dangerouslySetInnerHTML={{__html: Translations.get('ModalBoxPincallErrorUnique')}}
				></div>

				<div className="pincall-modal-box__text">{Translations.get('ModalBoxPincallErrorUniqueText')}</div>

				<div className="pincall-modal-box__text -margin-small">{Translations.get('ModalBoxTrialConversionChatBonusCodeText')}</div>

				<div>
					<button onClick={this.onOpenPaytourClick} className="pincall-modal-box__phone-number-btn btn -btn-color-special -click-material">
						<span className="h-text-transform-none">{Translations.get('ModalBoxTrialConversionChatBonusCodeBtnLabel')}</span>
						<i className="icon -icon-single-arrow-right-line " />
					</button>
				</div>

				<div className="pincall-modal-box__text">{Translations.get('ModalBoxPincallVerificationNotice')}</div>
			</div>;
		} else if (this.state.isVerified) {
			modalContent = <div>
				{timer}
				<div className="pincall-modal-box__verified-text">
					<i className="icon -icon-success-rounded-thinner " />
					{Translations.get('ModalBoxPincallVerified')}
				</div>

				<button onClick={this.onChoseAmateurBtnClick} className="pincall-modal-box__verified-btn btn -btn-color-special -click-material">
					<span className="h-text-transform-none">{Translations.get('ModalBoxPincallVerifiedBtnLabel')}</span>
					<i className="icon -icon-single-arrow-right-line " />
				</button>
			</div>;
		} else {
			modalContent = <div>
				{timer}
				<div className="pincall-modal-box__headline">{Translations.get('ModalBoxPincallPhoneNumberHeadline')}</div>
				<div className="pincall-modal-box__subheadline">{Translations.get('ModalBoxTrialConversionChatSubheadline')}</div>
				<div className="pincall-modal-box__input">
				</div>
				<div className="pincall-modal-box__hint">{Translations.get('ModalBoxTrialConversionChatVerificationHint')}</div>
				{this.state.errorCode &&
				<div className="pincall-modal-box__error">{ModalBoxTrialConversionChat.getTranslationByErrorCode(this.state.errorCode)}</div>}

				<button onClick={this.onOpenTrialPaytourClick} className="pincall-modal-box__phone-number-btn btn -btn-color-special -click-material">
					<span className="h-text-transform-none">{Translations.get('ModalBoxTrialConversionChatBtnLabel')}</span>
					<i className="icon -icon-single-arrow-right-line " />
				</button>

				<div className="pincall-modal-box__hint">*{Translations.get('ModalBoxPincallVerificationNotice')}</div>
				<div className="pincall-modal-box__sealbox">
					<div className="pincall-modal-box__seal -datasecure">
						<img src="/assets/img/modal/seals/siegel_datasecure.png" alt="VISIT-X DATA SECURE" />
					</div>
					<div className="pincall-modal-box__seal -ssl">
						<img src="/assets/img/modal/seals/siegel_ssl.png" alt="VISIT-X SSL gesichert" />
					</div>
					<div className="pincall-modal-box__seal -nospam">
						<img src="/assets/img/modal/seals/siegel_no_spamV2.svg" alt="VISIT-X Kein SPAM Verifizierung" />
					</div>
				</div>
			</div>;
		}

		const content = (
			<Modal containerWidth={650}
			       containerHeight={fixedHeight}
			       dynamicSize={true}
			       className={'modal-box__pincall -dynamic-size -theme-dialog' + (this.props.isMobile ? ' -mobile' : '')}
			       onClose={this.props.onClose}
			       renderInline={true}
			       hideCloseButton={true}
			       ref={(ref) => this.getModalRef(ref)}
			>
				{this.props.isMobile &&
				<div className="pincall-modal-box__logo"><img src="/assets/img/vxlogo_shade_redblack.svg" width="150" /></div>}
				{modalContent}
			</Modal>
		);

		return content;
	}
}

ModalBoxTrialConversionChat.propTypes = {
	isMobile:  PropTypes.bool,
	countdown: PropTypes.object,
	onClose:   PropTypes.func,
};

ModalBoxTrialConversionChat.defaultProps = {
	isMobile: false,
};

const ModalBoxWithCountdown = withCountdown(ModalBoxTrialConversionChat);

export default ModalBoxWithCountdown;
